@media (max-width: 1920px) {
    [data-d~="@1920=wh=110px"] { width: 110px }
    [data-d~="@1920=wh=180px"] { width: 180px }

    [data-d~="@1920=mx-wh=290px"] { max-width: 290px }


    [data-d~="@1920=ft-se=11rem"] { font-size: 11rem }
    [data-d~="@1920=ft-se=24rem"] { font-size: 24rem }

}



@media (max-width: 1600px) {
    [data-d~="@1600=br-wh=3px"] { border-width: 3px }

    [data-d~="@1600=wh=50px"] { width: 50px }
    [data-d~="@1600=wh=80px"] { width: 80px }
    [data-d~="@1600=wh=90px"] { width: 90px }
    [data-d~="@1600=wh=120px"] { width: 120px }
    [data-d~="@1600=wh=150px"] { width: 150px }

    [data-d~="@1600=mx-wh=270px"] { max-width: 270px }


    [data-d~="@1600=ht=50px"] { height: 50px }
    [data-d~="@1600=ht=120px"] { height: 120px }
    [data-d~="@1600=ht=180px"] { height: 180px }
    [data-d~="@1600=ht=220px"] { height: 220px }

    [data-d~="@1600=ft-se=11rem"] { font-size: 11rem }
    [data-d~="@1600=ft-se=12rem"] { font-size: 12rem }
    [data-d~="@1600=ft-se=14rem"] { font-size: 14rem }
    [data-d~="@1600=ft-se=20rem"] { font-size: 20rem }
    [data-d~="@1600=ft-se=24rem"] { font-size: 24rem }
    [data-d~="@1600=ft-se=30rem"] { font-size: 30rem }


    [data-d~="@1600=rt=5px"] { right: 5px }

    [data-d~="@1600=bm=40px"] { bottom: 40px }


    [data-d~="@1600=pg=10px"] { padding: 10px }


    [data-d~="@1600=pg-tp=10px"] { padding-top: 10px }
    [data-d~="@1600=pg-tp=20px"] { padding-top: 20px }

    [data-d~="@1600=pg-bm=il"] { padding-bottom: initial }
    [data-d~="@1600=pg-bm=20px"] { padding-bottom: 20px }


    [data-d~="@1600=mn-tp=il"] { margin-top: initial }
    [data-d~="@1600=mn-tp=5px"] { margin-top: 5px }
    [data-d~="@1600=mn-tp=10px"] { margin-top: 10px }
    [data-d~="@1600=mn-tp=20px"] { margin-top: 20px }

    [data-d~="@1600=mn-bm=5px"] { margin-bottom: 5px }
    [data-d~="@1600=mn-bm=15px"] { margin-bottom: 15px }
    [data-d~="@1600=mn-bm=20px"] { margin-bottom: 20px }

    [data-d~="@1600=mn-lt=40px"] { margin-left: 40px }

}



@media (max-width: 1450px) {
    [data-d~="@1450=wh=40px"] { width: 40px }
    [data-d~="@1450=wh=70px"] { width: 70px }
    [data-d~="@1450=wh=100px"] { width: 100px }
    [data-d~="@1450=wh=140px"] { width: 140px }
    [data-d~="@1450=wh=cc(1)"] { width: calc(100% - 80px) }

    [data-d~="@1450=mx-wh=240px"] { max-width: 240px }


    [data-d~="@1450=ht=20px"] { height: 20px }
    [data-d~="@1450=ht=40px"] { height: 40px }
    [data-d~="@1450=ht=140px"] { height: 140px }

    [data-d~="@1450=ft-se=16rem"] { font-size: 16rem }
    [data-d~="@1450=ft-se=18rem"] { font-size: 18rem }
    [data-d~="@1450=ft-se=24rem"] { font-size: 24rem }

    [data-d~="@1450=tp=130px"] { top: 130px }

    [data-d~="@1450=rt=-40px"] { right: -40px }

    [data-d~="@1450=pg-tp=20px"] { padding-top: 20px }

    [data-d~="@1450=pg-bm=20px"] { padding-bottom: 20px }

    [data-d~="@1450=mn-tp=-50px"] { margin-top: -50px }
    [data-d~="@1450=mn-tp=10px"] { margin-top: 10px }
    [data-d~="@1450=mn-tp=25px"] { margin-top: 25px }

    [data-d~="@1450=mn-bm=-50px"] { margin-bottom: -50px }

    [data-d~="@1450=mn-lt=5px"] { margin-left: 5px }
    [data-d~="@1450=mn-lt=20px"] { margin-left: 20px }

}



@media (max-width: 1300px) {
    [data-d~="@1300=mx-wh=220px"] { max-width: 220px }

}



@media (max-width: 1200px) {
    [data-d~="@1200=wh=4px"] { width: 4px }
    [data-d~="@1200=wh=50px"] { width: 50px }
    [data-d~="@1200=wh=80px"] { width: 80px }
    [data-d~="@1200=wh=100px"] { width: 100px }

    [data-d~="@1200=ht=100px"] { height: 100px }
    [data-d~="@1200=ht=180px"] { height: 180px }
    [data-d~="@1200=ht=140px"] { height: 140px }

    [data-d~="@1200=mn-tp=il"] { margin-top: initial }
    [data-d~="@1200=mn-tp=5px"] { margin-top: 5px }
    [data-d~="@1200=mn-tp=20px"] { margin-top: 20px }

    [data-d~="@1200=mn-lt=20px"] { margin-left: 20px }

}

@media (max-width: 1000px) {
    [data-d~="@1000=gd-aa=il"] { grid-area: initial }


    [data-d~="@1000=at-ro=il"] { aspect-ratio: initial }


    [data-d~="@1000=wh=il"] { width: initial }
    [data-d~="@1000=wh=30px"] { width: 30px }
    [data-d~="@1000=wh=40px"] { width: 40px }
    [data-d~="@1000=wh=50px"] { width: 50px }
    [data-d~="@1600=wh=50%"] { width: 50% }


    [data-d~="@1000=ht=30px"] { height: 30px }
    [data-d~="@1000=ht=50px"] { height: 50px }
    [data-d~="@1000=ht=150px"] { height: 150px }


    [data-d~="@1000=mn-ht=il"] { min-height: initial }


    [data-d~="@1000=dy=ne"] { display: none }
    [data-d~="@1000=dy=fx"] { display: flex }


    [data-d~="@1000=pn=il"] { position: initial }
    [data-d~="@1000=pn=fd"] { position: fixed }

    [data-d~="@1000=tp=10px"] { top: 10px }

    [data-d~="@1000=rt=10px"] { right: 10px }

    [data-d~="@1000=lt=10px"] { left: 10px }


    [data-d~="@1000=or=1"] { order: 1 }
    [data-d~="@1000=or=2"] { order: 2 }
    [data-d~="@1000=or=3"] { order: 3 }


    [data-d~="@1000=ft-se=14rem"] { font-size: 14rem }
    [data-d~="@1000=ft-se=16rem"] { font-size: 16rem }
    [data-d~="@1000=ft-se=18rem"] { font-size: 18rem }
    [data-d~="@1000=ft-se=20rem"] { font-size: 20rem }
    [data-d~="@1000=ft-se=30rem"] { font-size: 30rem }

    [data-d~="@1000=co=bk"] { color: var(--color-bk) }


    [data-d~="@1000=tt-an=cr"] { text-align: center }


    [data-d~="@1000=pg=il"] { padding: initial }
    [data-d~="@1000=pg=5px"] { padding: 5px }
    [data-d~="@1000=pg=10px"] { padding: 10px }
    [data-d~="@1000=pg=30px0"] { padding: 30px 0 }

    [data-d~="@1000=pg-tp=il"] { padding-top: initial }
    [data-d~="@1000=pg-tp=10px"] { padding-top: 10px }
    [data-d~="@1000=pg-tp=20px"] { padding-top: 20px }
    [data-d~="@1000=pg-tp=30px"] { padding-top: 30px }

    [data-d~="@1000=pg-rt=il"] { padding-right: initial }

    [data-d~="@1000=pg-bm=il"] { padding-bottom: initial }
    [data-d~="@1000=pg-bm=10px"] { padding-bottom: 10px }
    [data-d~="@1000=pg-bm=30px"] { padding-bottom: 30px }

    [data-d~="@1000=pg-lt=il"] { padding-left: initial }


    [data-d~="@1000=mn-tp=5px"] { margin-top: 5px }
    [data-d~="@1000=mn-tp=10px"] { margin-top: 10px }
    [data-d~="@1000=mn-tp=20px"] { margin-top: 20px }
    [data-d~="@1000=mn-tp=40px"] { margin-top: 40px }

    [data-d~="@1000=mn-rt=il"] { margin-right: initial }
    [data-d~="@1000=mn-rt=10px"] { margin-right: 10px }

    [data-d~="@1000=mn-bm=5px"] { margin-bottom: 5px }
    [data-d~="@1000=mn-bm=20px"] { margin-bottom: 20px }

    [data-d~="@1000=mn-lt=il"] { margin-left: initial }
    [data-d~="@1000=mn-lt=5px"] { margin-left: 5px }
    [data-d~="@1000=mn-lt=10px"] { margin-left: 10px }
    [data-d~="@1000=mn-lt=40px"] { margin-left: 40px }


    [data-d~="@1000=bx-sw=1"] { box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 15%) }


    [data-d~="@1000=an-dn=20s"] { animation-duration: 20s }

    [data-d~="@1000=br-lt-cr=3"] { border-left-color: var(--color-3) }

}

@media (max-width: 900px) {
    [data-d~="@900=br=ne"] { border: none }
    [data-d~="@900=br=ne!"] { border: none !important }

    [data-d~="@900=br-se=sd"] { border-style: solid }

    [data-d~="@900=br-cr=3"] { border-color: #cccccc }

    [data-d~="@900=br-rs=3px"] { border-radius: 3px }

    [data-d~="@900=br-wh=1px"] { border-width: 1px }


    [data-d~="@900=wh=il"] { width: initial }
    [data-d~="@900=wh=100px"] { width: 100px }
    [data-d~="@900=wh=120px"] { width: 120px }
    [data-d~="@900=wh=40px"] { width: 40px }
    [data-d~="@900=wh=60px"] { width: 60px }
    [data-d~="@900=wh=70px"] { width: 70px }
    [data-d~="@900=wh=180px"] { width: 180px }
    [data-d~="@900=wh=250px"] { width: 250px }
    [data-d~="@900=wh=100%"] { width: 100% }

    [data-d~="@900=mn-wh=220px"] { min-width: 220px }
    [data-d~="@900=mn-wh=245px"] { min-width: 245px }

    [data-d~="@900=ht=il"] { height: initial }
    [data-d~="@900=ht=10px"] { height: 10px }
    [data-d~="@900=ht=40px"] { height: 40px }
    [data-d~="@900=ht=60px"] { height: 60px }
    [data-d~="@900=ht=160px"] { height: 160px }
    [data-d~="@900=ht=180px"] { height: 180px }
    [data-d~="@900=ht=50vw"] { height: 50vw }
    [data-d~="@900=ht=70vw"] { height: 70vw }

    [data-d~="@900=mn-ht=35px"] { min-height: 35px }


    [data-d~="@900=ft-se=12rem"] { font-size: 12rem }
    [data-d~="@900=ft-se=14rem"] { font-size: 14rem }
    [data-d~="@900=ft-se=18rem"] { font-size: 18rem }
    [data-d~="@900=ft-se=40rem"] { font-size: 40rem }

    [data-d~="@900=lr-sg=1px"] { letter-spacing: 1px }

    [data-d~="@900=tt-an=lt!"] { text-align: left !important }


    [data-d~="@900=pn=fd"] { position: fixed }

    [data-d~="@900=tp=10px"] { top: 10px }

    [data-d~="@900=rt=10px"] { right: 10px }
    [data-d~="@900=rt=20px"] { right: 20px }

    [data-d~="@900=lt=10px"] { left: 10px }


    [data-d~="@900=dy=ne"] { display: none }

    [data-d~="@900=or=1"] { order: 1 }
    [data-d~="@900=or=2"] { order: 2 }
    [data-d~="@900=or=3"] { order: 3 }
    [data-d~="@900=or=4"] { order: 4 }
    [data-d~="@900=or=5"] { order: 5 }
    [data-d~="@900=or=6"] { order: 6 }

    [data-d~="@900=pg=2px"] { padding: 2px }
    [data-d~="@900=pg=5px"] { padding: 5px }
    [data-d~="@900=pg=10px"] { padding: 10px }
    [data-d~="@900=pg=20px"] { padding: 20px }
    [data-d~="@900=pg=020px"] { padding: 0 20px }
    [data-d~="@900=pg=5px2px"] { padding: 5px 2px }
    [data-d~="@900=pg=5px10px"] { padding: 5px 10px }
    [data-d~="@900=pg=20px10px"] { padding: 20px 10px }

    [data-d~="@900=pg-tp=il"] { padding-top: initial }
    [data-d~="@900=pg-tp=5px"] { padding-top: 5px }
    [data-d~="@900=pg-tp=10px"] { padding-top: 10px }
    [data-d~="@900=pg-tp=15px"] { padding-top: 15px }
    [data-d~="@900=pg-tp=20px"] { padding-top: 20px }
    [data-d~="@900=pg-tp=25px"] { padding-top: 25px }
    [data-d~="@900=pg-tp=30px"] { padding-top: 30px }
    [data-d~="@900=pg-tp=35px"] { padding-top: 35px }
    [data-d~="@900=pg-tp=40px"] { padding-top: 40px }

    [data-d~="@900=pg-rt=10px"] { padding-right: 10px }
    [data-d~="@900=pg-rt=20px"] { padding-right: 20px }

    [data-d~="@900=pg-bm=il"] { padding-bottom: initial }
    [data-d~="@900=pg-bm=5px"] { padding-bottom: 5px }
    [data-d~="@900=pg-bm=10px"] { padding-bottom: 10px }
    [data-d~="@900=pg-bm=20px"] { padding-bottom: 20px }
    [data-d~="@900=pg-bm=30px"] { padding-bottom: 30px }

    [data-d~="@900=pg-lt=10px"] { padding-left: 10px }
    [data-d~="@900=pg-lt=20px"] { padding-left: 20px }



    [data-d~="@900=mn-tp=il"] { margin-top: initial }
    [data-d~="@900=mn-tp=2px"] { margin-top: 2px }
    [data-d~="@900=mn-tp=5px"] { margin-top: 5px }
    [data-d~="@900=mn-tp=6px"] { margin-top: 6px }
    [data-d~="@900=mn-tp=10px"] { margin-top: 10px }
    [data-d~="@900=mn-tp=20px"] { margin-top: 20px }
    [data-d~="@900=mn-tp=30px"] { margin-top: 30px }
    [data-d~="@900=mn-tp=40px"] { margin-top: 40px }

    [data-d~="@900=mn-bm=10px"] { margin-bottom: 10px }

    [data-d~="@900=mn-lt=il"] { margin-left: initial }
    [data-d~="@900=mn-lt=5px"] { margin-left: 5px }
    [data-d~="@900=mn-lt=10px"] { margin-left: 10px }
    [data-d~="@900=mn-lt=20px"] { margin-left: 20px }

}



@media (max-width: 600px) {
    [data-d~="@600=mn-tp=5px"] { margin-top: 5px }
    [data-d~="@600=mn-tp=10px"] { margin-top: 10px }
    [data-d~="@600=mn-tp=20px"] { margin-top: 20px }

    [data-d~="@600=mn-lt=il"] { margin-left: initial }

}



@media (max-width: 300px) {
    [data-d~="@300=mn-wh=il"] { min-width: initial }

    [data-d~="@300=mn-tp=20px"] { margin-top: 20px }

}
